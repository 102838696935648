window.eventBus = {
    id: 0, event: {},
    on(eventName, callback) {
        const token = 'eventBusToken_' + this.id++
        if (eventName in this.event) this.event[eventName][token] = callback
        else this.event[eventName] = { [token]: callback }
        return token
    },
    one(eventName, callback) {
        const token = 'eventBusOneToken'
        if (eventName in this.event) this.event[eventName][token] = callback
        else this.event[eventName] = { [token]: callback }
    },
    once(eventName, callback) {
        let token = 'eventBusOnceToken_' + this.id++
        if (eventName in this.event) this.event[eventName][token] = callback
        else this.event[eventName] = { [token]: callback }
    },
    emit(eventName, ...parameter) {
        const eventObj = this.event[eventName]
        if (!eventObj) return
        Object.values(eventObj).forEach(func => func(...parameter))
        Object.keys(eventObj).forEach(key => (key.startsWith('eventBusOnceToken_') && delete eventObj[key]))
    },
    off(offName) {
        if (!offName) return Object.keys(this.event).forEach(k => delete this.event[k])
        if (offName.startsWith('eventBusToken_')) {
            const eventObj = Object.values(this.event).find(v => offName in v)
            if (eventObj) delete eventObj[offName]
        } else {
            delete this.event[offName]
        }
    }
}