<template>
    <router-view></router-view>
</template>
<!--
创建日期 : 2024/3/20
-->
<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: 'layout-parent-menu',
    setup() {
        return {}
    }
});
</script>

<style scoped lang="scss">

</style>