import router from '@/router';
export default {
    change({ commit }, param) {
        return new Promise((resolve, reject) => {
            commit('setList', param);
        });
    },
    open({ commit, state }, param) {
        return new Promise((resolve, reject) => {
            const list = [...state.list];

            const flt = list.filter(item => item.pathString === param.pathString);

            if (!flt.length) {
                list.push({ ...param });
                commit('setList', list);
            }

            commit('setActive', param.pathString);
            resolve();
        });
    },
    close({ commit, state }, param) {
        return new Promise((resolve, reject) => {
            let list = [...state.list];
            const flt = list.filter(item => item.pathString === param.pathString);

            if (flt.length) {
                /** 需要移除的项 */
                const waitRemove = flt[0];

                /** 需要打开的项：当关闭当前tabs需要 */
                let waitOpen;

                // 当关闭当前tab时需要打开其他页面
                if (state.active === param.pathString) {
                    const idx = list.indexOf(waitRemove);
                    if (idx === 0 && list.length > 1) {
                        // 第一个标签打开后面
                        waitOpen = list[1];
                    } else if (idx !== 0 && list.length > 1) {
                        // 其他打开前面
                        waitOpen = list[idx - 1];
                    } else {
                        router.replace({ name: 'main' });
                    }
                }

                list = list.filter(item => item !== waitRemove);
                commit('setList', list);

                if (waitOpen) {
                    router.replace({ path: waitOpen.path,query:waitOpen.query,params:waitOpen.params  });
                }
            }

            resolve();
        });
    },
    rename({ commit, state }, param) {
        let list = [...state.list];
        const flt = list.filter(item => item.path === param.path);

        console.log(1);

        if (flt.length) {
            flt[0].name = param.name;

            commit('setList', list);
        }
    },
    clear({ commit }) {
        commit('setList', []);
    },
    closeOthers({ commit, state }, path) {
        let list = [...state.list];
        const flt = list.filter(item => item.path === path);

        if (flt.length) {
            router.replace({ path: path });
            commit('setList', [flt[0]]);
        }
    },

    logout({ commit, dispatch }) {

        commit('setList', []);
        commit('setToken', '');
        commit('mobile', '');

        window.localStorage.removeItem('token');
        window.localStorage.removeItem('mobile');
        window.localStorage.removeItem('tabs');
        window.localStorage.removeItem('userInfo')
        window.localStorage.removeItem('permission')
        router.replace({ name: 'login' });
    },
    updateAccount({ commit }, {id,token,mobile}) {
        return new Promise((resolve, reject) => {
            token && commit('setToken', token);
            mobile && commit('setMobile', mobile);
            resolve()
        });
    },

}