<template>
    <div class="app">

<!--        <transition name="fade-forwards">-->
<!--            <router-view v-if="routeNameCurrent"/>-->
<!--        </transition>-->
        <router-view v-slot="{Component}">
            <transition name="fade-forwards">
                <component  :is="Component"/>
            </transition>
        </router-view>
    </div>
</template>

<script>
import {defineComponent, reactive, toRefs, ref, onMounted, watch, nextTick, computed} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import _ from 'lodash'
export default defineComponent({
    name: 'App',
    setup() {
        const $route = useRoute(), $router = useRouter(), store = useStore()
        const routeNameCurrent = computed(() => {
            return $route.name;
        })
        onMounted(()=>{
            window.addEventListener('resize', _.throttle(() => eventBus.emit('window-on-resize'), 300, {leading: false}), false)
            // window.onresize = _.throttle(() => eventBus.emit('window-on-resize'), 300, {leading: false})
        })
        return {
            routeNameCurrent
        }
    }
});
</script>

<style lang="scss">
.app {
    width: 100%;
    height: 100%;

    .on-permission {
        position: fixed;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 99;
    }
}
</style>
