import {createRouter, createWebHistory} from 'vue-router'
import store from '@/store';
import routes from './routes';


const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    console.log(to, '0000')
    const isMenuItem = !!to.matched.filter(item => item.name === 'main').length && to.name !== 'main';
    const token = window.localStorage.getItem('token') ?? '';
    if (isMenuItem) {
        let queryString = ''
        for (let key in to.query) {
            if (key) {
                let link = `&${key}=${to.query[key]}`
                queryString += link
            }
        }
        queryString = queryString && queryString.substr(1);
        let pathString = queryString ? `${to.path}?${queryString}` : `${to.path}`;
        store.dispatch('open', {
            pathString: pathString,
            path: to.path,
            query: to.query,
            params: to.params,
            name: to.meta.title
        });
    }

    if (to.name === 'main') {

        if (token) {
            if (store.state.list && store.state.list.length) {

                // 当路由为根时，自动自动打开第一个
                const first = store.state.list[0];
                if (token) {
                    router.replace({path: first.path});
                } else {
                    window.localStorage.clear()
                    router.replace({path: '/login'});
                }

            }
        } else {
            window.localStorage.clear()
            router.replace({path: '/login'});
        }

    } else {
        if (to.name != 'login' && !token) {
            router.replace({path: '/login'});
        }
    }
    next()

});


export default router