import menu from '@/_config/menu';
const routes= [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "modules/_login" */ '@/views/_login/login.vue'),
    },
    {
        path: '',
        name: 'main',
        component: () => import(/* webpackChunkName: "modules/_main" */ '@/views/_main/main.vue'),
        children: menu,
    },
]

export default routes;