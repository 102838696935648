import LayoutParentMenu from "@/components/layout-parent-menu/layout-parent-menu.vue";
// --------------------!注意!-------------------
//
//       menu的内容会全部被作为**子路由**注入，
//       所以path写'/'开头的可能会引发一波团灭
//      #375eff     #61e5c6
//   父级路由需要指定component为FrameParentLayout
//                 否则子级会被秒杀
//
// ---------------------------------------------

/**
 * 权限配置：
 * 分类字典项管理
 * 分类字典项管理   dic/dic-class
 *
 * 题库管理
 * 我的题库 subject/subject-class
 * 导入题库  subject/topic-list
 * 视频库管理
 * 学习视频 video/video-class
 *
 * 班级管理
 * 班级列表 class/class-list
 *
 * 学员管理
 * 学员列表 member/student
 * 报名课程管理 member/up-class
 * 报名列表 member/apply-list
 *
 * 考试管理
 * 考卷列表 exam/exam-list
 * 评阅试卷 exam/check-list
 * 成绩管理 exam/scroll-list
 * 人脸识别 exam/face-list
 *
 * 学校管理
 * 学校信息 school/school-detail
 *
 * 讲师管理
 * 讲师列表  teacher/teacher-list
 *
 * 提问管理
 * 提问列表 question/question-list
 *
 * 签到管理
 * 签到列表 sign-up/sign-up-list
 *
 * 资料管理
 * 资料上传 infomation/infomation-list
 *
 * 系统管理
 * 系统用户 system/user-list
 * */
const menu = [
  {
    path: "dic",
    name: "dic",
    component: LayoutParentMenu,
    meta: {
      title: "分类字典管理",
      icon: "ico-menu-home",
      iconUrl:
        "https://school-baoshanrencaixueyuan.oss-cn-shanghai.aliyuncs.com/RD/svg/icon-1.svg",
      permission:'dic',
    },
    children: [
      {
        path: "dic-class",
        name: "dic-class",
        meta: {
          title: "分类字典管理",
          permission:'dic/dic-class',
        },
        component: () =>
          import(
            /* webpackChunkName: "modules/dic-class" */ "@/views/dic-class/dic-class.vue"
          ),
      },
      {
        path: "dic-two",
        name: "dic-two",
        meta: {
          title: "分类",
          hidden: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "modules/topic-list"*/ "@/views/dic-class/dic-two.vue"
          ),
      },
    ],
  },

  {
    path: "subject",
    name: "subject",
    component: LayoutParentMenu,
    meta: {
      title: "题库管理",
      icon: "ico-menu-home",
      permission: 'subject',
      iconUrl:
        "https://school-baoshanrencaixueyuan.oss-cn-shanghai.aliyuncs.com/RD/svg/icon-2.svg",
    },
    children: [
      {
        path: "subject-class",
        name: "subject-class",
        meta: {
          title: "我的题库",
          permission: 'subject/subject-class'
        },
        component: () =>
          import(
            /* webpackChunkName: "modules/subject-class"*/ "@/views/subject/subject-class/subject-class.vue"
          ),
      },
      {
        path: "topic-list",
        name: "topic-list",
        meta: {
          title: "导入题库",
          hidden: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "modules/topic-list"*/ "@/views/subject/topic/topic-list.vue"
          ),
      },
    ],
  },
  {
    path: "video",
    name: "video-part",
    component: LayoutParentMenu,
    meta: {
      title: "视频库管理",
      icon: "ico-menu-home",
      iconUrl:
        "https://school-baoshanrencaixueyuan.oss-cn-shanghai.aliyuncs.com/RD/svg/icon-3.svg",
      permission: 'video'
    },
    children: [
      {
        path: "video-class",
        name: "video-class",
        meta: {
          title: "学习视频",
          permission: 'video/video-class'
        },
        component: () =>
          import(
            /* webpackChunkName: "modules/video-class"*/ "@/views/video/video.vue"
          ),
      },
    ],
  },
  {
    path: "class",
    name: "class",
    component: LayoutParentMenu,
    meta: {
      title: "班级管理",
      icon: "ico-menu-home",
      iconUrl:
        "https://school-baoshanrencaixueyuan.oss-cn-shanghai.aliyuncs.com/RD/svg/icon-4.svg",
      permission: 'class'
    },
    children: [
      {
        path: "class-list",
        name: "class-list",
        meta: {
          title: "班级列表",
          permission: 'class/class-list'
        },
        component: () =>
          import(
            /* webpackChunkName: "modules/classs"*/ "@/views/class/class-list/class-list.vue"
          ),
      },
    ],
  },
  {
    path: "member",
    name: "member",
    component: LayoutParentMenu,
    meta: {
      title: "学员管理",
      icon: "ico-menu-home",
      iconUrl:
        "https://school-baoshanrencaixueyuan.oss-cn-shanghai.aliyuncs.com/RD/svg/icon-5.svg",
      permission: 'member'
    },
    children: [
      {
        path: "student",
        name: "student",
        meta: {
          title: "学员列表",
          permission: 'member/student'
        },
        component: () =>
            import(
                /* webpackChunkName: "modules/member/student"*/ "@/views/member/student/student.vue"
                ),
      },
      {
        path: "up-class",
        name: "up-class",
        meta: {
          title: "报名课程管理",
          permission: 'member/up-class'
        },
        component: () =>
            import(
                /* webpackChunkName: "modules/member/up-class"*/ "@/views/member/up-class/up-class.vue"
                ),
      },
      {
        path: "apply-list",
        name: "apply-list",
        meta: {
          title: "报名列表",
          permission: 'member/apply-list'
        },
        component: () =>
          import(
            /* webpackChunkName: "modules/member/apply-list"*/ "@/views/member/sign-up/sign-up.vue"
          ),
      },
    ],
  },
  {
    path: "exam",
    name: "exam",
    component: LayoutParentMenu,
    meta: {
      title: "考试管理",
      icon: "ico-menu-home",
      iconUrl:
        "https://school-baoshanrencaixueyuan.oss-cn-shanghai.aliyuncs.com/RD/svg/icon-6.svg",
      permission: 'exam'
    },
    children: [
      {
        path: "exam-list",
        name: "exam-list",
        meta: {
          title: "考卷列表",
          permission: 'exam/exam-list'
        },
        component: () =>
          import(
            /* webpackChunkName: "modules/exams"*/ "@/views/exam/exam-list/exam-list.vue"
          ),
      },
      {
        path: "check-list",
        name: "check-list",
        meta: {
          title: "评阅试卷",
          permission: 'exam/check-list'
        },
        component: () =>
          import(
            /* webpackChunkName: "modules/exams"*/ "@/views/exam/check-list/check-list.vue"
          ),
      },
      {
        path: "scroll-list",
        name: "scroll-list",
        meta: {
          title: "成绩管理",
          permission: 'exam/scroll-list'
        },
        component: () =>
          import(
            /* webpackChunkName: "modules/exams"*/ "@/views/exam/scroll-list/scroll-list.vue"
          ),
      },
      {
        path: "face-list",
        name: "face-list",
        meta: {
          title: "人脸识别",
          permission: 'exam/face-list'
        },
        component: () =>
          import(
            /* webpackChunkName: "modules/exams"*/ "@/views/exam/face-list/face-list.vue"
          ),
      },
    ],
  },
  {
    path: "school",
    name: "school",
    component: LayoutParentMenu,
    meta: {
      title: "学校管理",
      icon: "ico-menu-home",
      iconUrl:
          "https://school-baoshanrencaixueyuan.oss-cn-shanghai.aliyuncs.com/RD/svg/icon-7.svg",
      permission: 'school'
    },
    children: [
      {
        path: "school-detail",
        name: "school-detail",
        meta: {
          title: "学校信息",
          permission: 'school/school-detail'
        },
        component: () =>
            import(
                /* webpackChunkName: "modules/school-detail"*/ "@/views/school/index.vue"
                ),
      },
    ],
  },
  {
    path: "teacher",
    name: "teacher",
    component: LayoutParentMenu,
    meta: {
      title: "讲师管理",
      icon: "ico-menu-home",
      iconUrl:
          "https://school-baoshanrencaixueyuan.oss-cn-shanghai.aliyuncs.com/RD/svg/icon-8.svg",
      permission: 'teacher'
    },
    children: [
      {
        path: "teacher-list",
        name: "teacher-list",
        meta: {
          title: "讲师列表",
          permission: 'teacher/teacher-list'
        },
        component: () =>
            import(
                /* webpackChunkName: "modules/teacher-list"*/ "@/views/lecturer/index.vue"
                ),
      },
    ],
  },
  {
    path: "question",
    name: "question",
    component: LayoutParentMenu,
    meta: {
      title: "提问管理",
      icon: "ico-menu-home",
      iconUrl:
          "https://school-baoshanrencaixueyuan.oss-cn-shanghai.aliyuncs.com/RD/svg/icon-9.svg",
      permission: 'question'
    },
    children: [
      {
        path: "question-list",
        name: "question-list",
        meta: {
          title: "提问列表",
          permission: 'question/question-list'
        },
        component: () =>
            import(
                /* webpackChunkName: "modules/question-list"*/ "@/views/question/index.vue"
                ),
      },
    ],
  },
  {
    path: "sign-up",
    name: "sign-up",
    component: LayoutParentMenu,
    meta: {
      title: "签到管理",
      icon: "ico-menu-home",
      iconUrl:
          "https://school-baoshanrencaixueyuan.oss-cn-shanghai.aliyuncs.com/RD/svg/icon-10.svg",
      permission: 'sign-up'
    },
    children: [
      {
        path: "sign-up-list",
        name: "sign-up-list",
        meta: {
          title: "签到列表",
          permission: 'sign-up/sign-up-list'
        },
        component: () =>
            import(
                /* webpackChunkName: "modules/sign-up-list"*/ "@/views/sign-in/index.vue"
                ),
      },
    ],
  },
  {
    path: "infomation",
    name: "infomation",
    component: LayoutParentMenu,
    meta: {
      title: "资料管理",
      icon: "ico-menu-home",
      iconUrl:
          "https://school-baoshanrencaixueyuan.oss-cn-shanghai.aliyuncs.com/RD/svg/icon-12.svg",
      permission: 'infomation'
    },
    children: [
      {
        path: "infomation-list",
        name: "infomation-list",
        meta: {
          title: "资料上传",
          permission: 'infomation/infomation-list'
        },
        component: () =>
            import(
                /* webpackChunkName: "modules/infomation-list"*/ "@/views/information/information.vue"
                ),
      },
    ],
  },
  {
    path: "system",
    name: "system",
    component: LayoutParentMenu,
    meta: {
      title: "系统管理",
      icon: "ico-menu-home",
      iconUrl:
          "https://school-baoshanrencaixueyuan.oss-cn-shanghai.aliyuncs.com/RD/svg/icon-11.svg",
      permission: 'system'
    },
    children: [
      {
        path: "user-list",
        name: "user-list",
        meta: {
          title: "系统用户",
          permission: 'system/user-list'
        },
        component: () =>
            import(
                /* webpackChunkName: "modules/system-user"*/ "@/views/system/user/user.vue"
                ),
      },
    ],
  },
  // {
  //     path: 'business-manage',
  //     name: 'business-manage',
  //     component: LayoutParentMenu,
  //     meta: {
  //         title: '企业管理',
  //         icon: 'ico-menu-company-job',
  //         iconUrl: 'http://job-onsite.oss-cn-shanghai.aliyuncs.com/public/svg/menu-qiyegangwei.svg',
  //     },
  //     children: [
  //         {
  //             path: 'company-management',
  //             name: 'company-management',
  //             meta: {
  //                 title: '企业管理',
  //             },
  //             component: () => import(/* webpackChunkName: "modules/company-management"*/ '@/views/company-management/company-list/index.vue'),
  //         },
  //         {
  //             path: 'company-details/:id',
  //             name: 'company-details',
  //             meta: {
  //                 title: '企业详情',
  //                 hidden: true
  //             },
  //             component: () => import(/* webpackChunkName: "modules/company-management"*/ '@/views/company-management/company-detail/index.vue'),
  //         },
  //         {
  //             path: 'post-manage',
  //             name: 'post-manage',
  //             meta: {
  //                 title: '岗位管理',
  //             },
  //             component: () => import(/* webpackChunkName: "modules/company-management"*/ '@/views/company-management/post-list/index.vue'),
  //         },
  //         {
  //             path: 'post-details/:id',
  //             name: 'post-details',
  //             meta: {
  //                 title: '岗位详情',
  //                 hidden: true
  //             },
  //             component: () => import(/* webpackChunkName: "modules/company-management"*/ '@/views/company-management/post-details/index.vue'),
  //         },
  //         {
  //             path: 'audit-management',
  //             name: 'audit-management',
  //             component: () => import(/* webpackChunkName: "modules/responsibility-template" */ '@/views/company-management/audit-management/index.vue'),
  //             meta: {
  //                 title: '审核管理',
  //                 // permission: 'similar-job',
  //                 alive: true,
  //             },
  //         },
  //         {
  //             path: 'responsibility-template',
  //             name: 'responsibility-template',
  //             component: () => import(/* webpackChunkName: "modules/responsibility-template" */ '@/views/company-management/responsibility-template/index.vue'),
  //             meta: {
  //                 title: '岗位工作职责模板管理',
  //                 // permission: 'similar-job',
  //                 alive: true,
  //             },
  //         },
  //     ]
  // },
  // // 运营管理
  // {
  //     path: 'markting',
  //     name: 'markting',
  //     component: LayoutParentMenu,
  //     meta: {
  //         title: '运营管理',
  //         icon: 'ico-menu-markting',
  //         iconUrl: 'http://job-onsite.oss-cn-shanghai.aliyuncs.com/public/svg/menu-yunying.svg',
  //     },
  //     children: [
  //         {
  //             path: 'cash-audit',
  //             name: 'cash-audit',
  //             component: () => import(/* webpackChunkName: "modules/marketing-management/cash-audit" */ '@/views/marketing-management/cash-audit/cash-audit.vue'),
  //             meta: {
  //                 title: '提现审核',
  //                 alive: true,
  //             },
  //         },
  //         {
  //             path: 'version-manage',
  //             name: 'version-manage',
  //             component: () => import(/* webpackChunkName: "modules/marketing-management/version-manage" */ '@/views/marketing-management/version-manage/version-manage.vue'),
  //             meta: {
  //                 title: '版本管理',
  //                 alive: true,
  //             },
  //         },
  //     ]
  // }
];
export default menu;
