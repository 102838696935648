export default {
    menuItem:{ },
    isBigMenu:true,
    tabs:[],
    list:[],
    active:'',
    account:{
        id: -1,
        token:localStorage.getItem('token')||'',
        name: '',
        mobile:localStorage.getItem('mobile')||'',
    }
}