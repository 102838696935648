export default {
    setMenuItem(state,payload){
        state.menuItem = payload
    },
    setMenuSize(state,payload){
        state.isBigMenu = payload
    },
    setList(state, payload) {
        const listString = [...payload].map(item => JSON.stringify(item)).join('!-!');
        window.localStorage.setItem('tabs', listString);

        state.list = payload;
    },
    setActive(state, payload) {
        state.active = payload;
    },
    setToken(state, payload){
        console.log(payload,'state')
        state.account.token = payload
    },
    setId(state, payload){
        state.account.id = payload
    },
    setMobile(state, payload){
        state.account.mobile = payload
    }
}