import store from '@/store';

/** localStorage中的tabs */
const storageTabs = window.localStorage.getItem('tabs');

if (storageTabs) {
    try {
        const list = storageTabs.split('!-!').map(item => {
            return JSON.parse(item);
        });

        store.commit('setList', list);
    } catch {}
}
